<template>
<div class="container-fluid">
        <ConfirmAlert ref="MyConfirmAlert" :title="AlertTitle" :message="AlertMessage" @Sure="YesIamSure($event)" :sureResult="sureResult" />
         <SuccessAlert ref="MySuccessAlert" :title="AlertTitle" :message="AlertMessage" :back="false"/>
 
            <div class="col-md-12">
                <div class="col-lg-12 col-md-12 ">
                <div class="row">
                  <div class="col-md-4">
                    <PerPage id="per_page" title="كل صفحة"  Mykey="per_page"  :MyValue="filter.per_page" @Select="HandleSelect('per_page',$event)"/>
                    </div>
                    <div class="col-md-4">
                    <div class="input-group no-border">
                        <!-- <input type="text" v-model="filter.name" class="form-control" placeholder="بحث">
                        <button @click="onSearch()" type="submit" class="btn btn-white btn-round btn-just-icon">
                            <i class="material-icons">search</i>
                            <div class="ripple-container"></div>
                        </button> -->
                    </div>
                    </div>
                    
                    <div class="col-md-4">
                        <router-link v-if="manageService('can_add')" :to="{name: 'admin.dashboard.subcategories.create', params: {category_uuid: filter.category_uuid}}" class="btn btn-primary btn-md" > <i class="fas fa-pencil-alt"> </i>
                            اضافة تصنيف فرعي</router-link>
                    </div>
                
                </div>
                </div>
            </div>

            <div class="col-md-12">
              <div class="card">
                <div class="card-header card-header-primary" style="text-align: center;">
                  <h4 class="card-title ">قائمة التصنيفات الفرعية</h4>
                </div>
                <div class="card-body" style="text-align:center;">
                  <div class="table-responsive">
                    <table class="table">
                      <thead class=" text-primary">
                        <th>
                          #
                        </th>
                        <th>
                          الاسم بالعربي
                        </th>
                        <th>
                          الاسم بالانجليزي
                        </th>
                        <th>
                          عدد المنتجات
                        </th>
                        <th>
                          offline_id
                        </th>

                        <th v-if="manageService('can_edit') || manageService('can_delete')">
                          اجراءات
                        </th>

                      </thead>
                      <tbody>
                        <tr v-for="(SubCategory,index) in SubCategoriesList.data" :key="index">
                          <td>
                            {{index+1}}
                          </td>
                          <td>
                            {{SubCategory.name.ar}}
                          </td>
                          <td>
                            {{SubCategory.name.en}}
                          </td>
                          <td>
                            {{SubCategory.ProductsCount}}
                          </td>
                          <td>
                            {{SubCategory.offline_id}}
                          </td>

                          <td class="text-primary" v-if="manageService('can_edit') || manageService('can_delete')">
                            <router-link  :to="{name: 'admin.dashboard.subcategories.view', params: {category_uuid: SubCategory.category_uuid,sub_category_uuid:SubCategory.uuid}}" class="btn btn-success btn-sm" > 
                             عرض</router-link>
                            <router-link v-if="manageService('can_edit')" :to="{name: 'admin.dashboard.subcategories.edit', params: {category_uuid: SubCategory.category_uuid,sub_category_uuid:SubCategory.uuid}}" class="btn btn-warning btn-sm" > 
                             تعديل</router-link>
                             <a href="" v-if="manageService('can_delete')" class="btn btn-danger btn-sm" @click.prevent="ShowConfirm(SubCategory)">
                             حذف</a> 
                          </td>
                        </tr>
                       </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12">
             <pagination v-if="SubCategoriesList.meta.last_page > 1" :pagination="SubCategoriesList" :offset="5" @paginate="onPaginationChage"></pagination> 
             </div>
</div>
</template>

<script>
import store from '../../../../store/index'
import {mapState} from 'vuex';

import Pagination from '../../../../components/Pagination.vue'
import PerPage from '../../../../components/PerPage'

import ConfirmAlert from '../../../../components/ConfirmAlert'
import SuccessAlert from '../../../../components/SuccessAlert'
export default {
  data(){
   return{
   filter:{
        name:'',
        page:1,
        paginate:1,
        per_page:'',
        category_uuid:'',
        limit:2,
    },  
     AlertTitle:'',
     AlertMessage:'',
     SelectedSubCategory:null,
     sureResult:true,
   }
  },
  computed:{
    ...mapState({
        SubCategoriesList:state=>state.admin.subcategories.SubCategoriesList,
    }),
    manageService: (state) => (service) => {
            return store.getters['admin/canManageService'](service);
    },
   },
   methods:{
     HandleSelect(key,value){
         this.filter[key]=value;
         this.onPaginationChage(1);
     },
        onPaginationChage(page){
       this.filter.page=page;
         store.dispatch('admin/fetchSubCategoriesList',this.filter).then((response)=>{
         }).catch((error)=>{
         })
       },
        onSearch(){
          store.commit('admin/PleaseStartLoading');
                this.filter.page=1;
                store.dispatch('admin/fetchSubCategoriesList',this.filter).then(()=>{
                  store.commit('admin/PleaseStopLoading');
                }).catch((error)=>{
                  store.commit('admin/PleaseStopLoading');
                });  
        },  
        YesIamSure(value){
        if(value && this.sureResult){
            this.sureResult=false;
            this.onDelete();
        }
        },
        CancelAlert(){
        this.sureResult=false;
        },
        ShowConfirm(SubCategory){
                this.AlertTitle="معلومات",
                this.AlertMessage="هل انت متاكد من الحذف ؟";
                this.$refs.MyConfirmAlert.showModel();
                this.SelectedSubCategory=SubCategory;
        },
         onDelete(){
           store.commit('admin/PleaseStartLoading');
          store.dispatch('admin/deleteSubCategory',{category_uuid:this.filter.category_uuid,sub_category_uuid:this.SelectedSubCategory.uuid}).then((Response)=>{
            store.commit('admin/PleaseStopLoading');
            this.sure=false;
           this.$refs.MyConfirmAlert.hideModel();
            this.AlertTitle="معلومات",
            this.AlertMessage="تمت الحذف بنجاح";
            this.$refs.MySuccessAlert.showModel();
            store.dispatch('admin/fetchSubCategoriesList',this.filter).then(()=>{
             this.sureResult=true;
            }).catch(error=>{

            })
          }).catch((error)=>{
         store.commit('admin/PleaseStopLoading');
        });
        

    },
   },
      components:{
       Pagination,
       PerPage,
       ConfirmAlert,
       SuccessAlert
   },

  beforeRouteEnter(to,from,next){   
    let category_uuid=to.params.category_uuid;  
      store.commit('admin/PleaseStartLoading');
      store.dispatch('admin/fetchSubCategoriesList',{category_uuid:category_uuid,page:1,name:'',paginate:1,limit:2}).then(()=>{
         store.commit('admin/PleaseStopLoading');
          next(vm=>{
            vm.filter.category_uuid=category_uuid;
          });  
      }).catch((error)=>{
         store.commit('admin/PleaseStopLoading');
       next(false);
      });             

    },
}
</script>

<style>

</style>